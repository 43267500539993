@import 'functions';
@import 'normalize';
@import 'shared';

/* PAGES */
@import 'page-done';
@import 'page-redirect';
@import 'page-ticket';

/* 3RD PARTY */
@import 'rating';

/* MQ */
@import 'mq';
