$defaults-border-radius: 5px;
$colors-primary: #CD1719;
$colors-secondary: #00853E;
$colors-white: #fff;
$colors-text: #2E384D;
$colors-invalid: #FF565A;
$colors-link: #67b6d4;
$colors-input-border: #BFE1EE;

.page-redirect {
  background: url(/static/swoop.svg) 0 150px no-repeat;
  background-size: cover;
  transition: background 0.5s ease-out;
  color: $colors-text;

  &::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(/static/swoop-invalid.svg); }

  &.invalid {
    background-image: url(/static/swoop-invalid.svg);

    .box {
      animation: shake 0.5s;
      animation-iteration-count: 2;

      .row input.active,
      .row input {
        border-color: $colors-invalid; }

      .divider {
        svg {
          opacity: 0; }
        .invalid {
          opacity: 1; } } }

    .view a {
      color: $colors-invalid;
      background: lighten($colors-invalid, 26%); } }

  &.loading {
    .row {
      opacity: 0.5; } }

  .wrap {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 480px; }

  .view {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;

    .logo {
      width: 160px;
      margin: 0 auto;
      height: 48px;
      background: url(/static/printnpay.svg) 0 0 no-repeat; }

    a {
      color: $colors-link;
      padding: 1rem 1.5rem;
      background: lighten($colors-input-border, 10%);
      border-radius: 3rem;
      transition: color 0.5s ease-out, background 0.5s ease-out;
      margin-bottom: 3rem; } }

  h1 {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 7vw;
    margin-bottom: 2rem;

    @media screen and (min-width: 480px) {
      font-size: 2rem; } }

  .box {
    max-width: 220px;
    margin: 2rem auto 5rem auto;
    position: relative;

    display: grid;
    grid-template-rows: 1fr 0.5rem 1fr;
    grid-gap: 2rem 0;

    .divider {
      svg {
        position: absolute;
        margin-left: -16px;
        transition: opacity 0.5s ease-out;
        opacity: 1; }

      .invalid {
        opacity: 0; } }

    .row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1.25rem;

      input {
        width: 100%;
        padding: 1rem;
        font-size: 2rem;
        text-align: center;
        background: #FFFFFF;
        text-transform: uppercase;
        transition: border-color 0.5s ease-out;
        font-weight: 500;
        min-width: 58px;
        border: {
          width: 1px 1px 4px 1px;
          style: solid;
          color: $colors-input-border;
          radius: $defaults-border-radius; }

        &::placeholder {
          color: $colors-input-border; }

        &.active {
          border-color: darken($colors-input-border, 20%); }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0; }
        &[type=number] {
          -moz-appearance: textfield; } } } } }
