$defaults-border-radius: 5px;

$colors-primary: #CD1719;
$colors-secondary: #00853E;

$colors-white: #fff;
$colors-header: #B9A5A5;
$colors-text: #2E384D;

$colors-button: #F1EDED;

$colors-state-unconfirmed: $colors-primary;
$colors-state-confirmed: $colors-secondary;

.page-ticket {

  .ticket {
    @extend .ticket-body;

    svg {
      width: 100%; }

    .rules {
      padding: 1rem 0 1rem;

      .rule {
        display: flex;
        border-bottom: 1px dashed rgba($colors-text, 0.2);
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        font-size: 1.6rem;
        font-weight: 400;

        .description {
          flex: 1 0 auto; }

        .amount {
          display: block;
          font-size: 1.4rem;
          color: rgba($colors-text, 0.5); }

        .price {
          flex: 0 0 5rem;
          text-align: right;
          align-self: flex-end; } } }

    .options {
      padding: 1rem 0 1rem;

      .option {
        cursor: pointer;
        font-size: 1.6rem;
        display: flex;
        border: 1px solid rgba($colors-text, 0.2);
        margin-bottom: 1rem;
        border-radius: 3px; }

      .price {
        flex: 0 0 60px;
        text-align: right;
        padding: 1.5rem 1.5rem 1.5rem 0;
        border-left: 1px dotted rgba($colors-text, 0.2); }

      .description {
        flex: 1 1;
        padding: 1.5rem 0 1.5rem 1.5rem; } }

    .header {
      padding: 3rem 3rem 5rem 3rem;
      display: flex;

      img {
        object-fit: contain;
        max-height: 10rem;
        flex: initial;
        width: 180px;
        min-width: 90px; }

      .meta {
        flex: 1;
        min-width: 140px;
        font-size: 1.4rem;
        line-height: 1.3rem;
        text-align: right;

        .date {
          color: $colors-text;
          display: block;
          margin-bottom: 0.5rem; }

        .time {
          color: rgba($colors-text, 0.5);
          font-weight: 400; } } }

    .contents {
      padding: 0 3rem; }

    .total {

      & > div {
        display: flex; }

      .label {
        flex: 1 0 auto; }

      .amount {
        flex: 0 0 8rem;
        text-align: right; }

      .tax {
        color: rgba($colors-text, 0.5);
        font-size: 1.6rem;
        margin-bottom: 0.5rem; }

      .price {
        font-size: 2rem;
        font-weight: 500; } }

    .issuers {
      margin-top: 5rem;
      border: 1px solid rgba($colors-text, 0.1);
      padding: 0 1rem 1rem 1rem;

      h3 {
        font-weight: 500;
        text-transform: uppercase;
        color: rgba($colors-text, 0.5); }

      button {
        font-size: 15px;
        background-size: 50px;
        height: 36px;
        background-position: center left;
        background-repeat: no-repeat;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: inline-block;
        text-align: left;
        padding: 1rem 0 1rem 65px;
        width: 100%;
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0.5rem; } }

      .ing {
        background-image: url(/static/banks/ing.svg); }
      .rabobank {
        background-image: url(/static/banks/rabobank.svg); }
      .abn_amro {
        background-image: url(/static/banks/abn-amro.svg); }
      .triodos_bank {
        background-image: url(/static/banks/triodos-bank.svg); }
      .asn_bank {
        background-image: url(/static/banks/asn-bank.svg); }
      .sns_bank {
        background-image: url(/static/banks/sns-bank.svg); }
      .regiobank {
        background-image: url(/static/banks/regiobank.svg); }
      .moneyou {
        background-image: url(/static/banks/moneyou.svg); }
      .van_lanschot {
        background-image: url(/static/banks/van-lanschot.svg); }
      .bunq {
        background-image: url(/static/banks/bunq.svg); }
      .knab {
        background-image: url(/static/banks/knab.svg); }
      .handelsbanken {
        background-image: url(/static/banks/svenska-handelsbanken.svg); } } } }
