* {
  box-sizing: border-box;
  &:focus {
    outline: none !important; } }

.preload,
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important; }

html, body {
  height: 100%;
  font-family: 'Rubik', sans-serif;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400; }

a {
  text-decoration: none; }

.ticket-body {
  max-width: 480px;
  min-width: 280px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 3rem; }

.loading-screen {
  max-width: 360px;
  margin: 0 auto;
  text-align: center;
  padding-top: 10rem;

  p {
    font-size: 1.6rem; }

  img {
    width: 50%;
    margin-bottom: 2rem; } }
