$defaults-border-radius: 5px;

$colors-primary: #CD1719;
$colors-secondary: #00853E;

$colors-white: #fff;
$colors-header: #B9A5A5;
$colors-text: #583A3B;

$colors-button: #F1EDED;

$colors-state-unconfirmed: $colors-primary;
$colors-state-confirmed: $colors-secondary;

.page-done {
  background-color: $colors-state-confirmed;
  padding: 4rem 1rem 1rem;

  .ticket {
    @extend .ticket-body;
    box-shadow: 0px 0px 120px rgba(darken($colors-state-unconfirmed, 10% ), 0.25);

    svg {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%); } }

  .contents-outer {
    padding: 1.5rem; }

  .header {
    padding: 3rem 0 1rem;
    text-align: center;

    img {
      object-fit: contain;
      max-height: 10rem;
      max-width: 60%; } }

  h2 {
    font-size: 1rem;
    text-transform: uppercase;
    color: $colors-header;
    margin: 0;
    text-align: center; }

  h3 {
    font-size: 2rem;
    margin: 0;
    text-align: center; }

  h4 {
    text-align: center;
    display: block; }

  .rating {
    margin-top: 3rem;
    min-height: 100px;
    padding: 1.5rem 1rem 1rem;
    background: $colors-button;

    .message {
      display: block;
      text-align: center;
      font-size: 1rem; }

    h2 {
      font-weight: 500; }

    .star-rating {
      font-size: 32px;
      margin: 0 auto;
      width: 200px; }

    button[type=submit] {
      background-color: lighten($colors-header, 25%);
      border: 1px solid lighten($colors-header, 15%);
      display: block;
      width: 100%;
      padding: 1rem 0;
      font-size: 1.2rem;
      color: $colors-header;
      font-weight: 500;
      box-shadow: 2px 2px 0 $colors-header;
      cursor: pointer; }

    .message {
      color: darken($colors-header, 15%); }

    textarea {
      margin-top: 1rem;
      width: 100%;
      padding: 1rem;
      border: 1px solid lighten($colors-header, 20%);
      color: $colors-text;
      margin-bottom: 0.5rem;
      line-height: 1.3rem;

      &::placeholder {
        color: lighten($colors-text, 50%); } } } }
